<template>
  <b-row class="">
    <b-col cols="12" md="9" xl="8">
      <b-card class="">
        <!-- Libellé -->
        <b-form-group label="Prix de vente">
          <template #label>
            Libelle de l'approvisionnement
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="libelle"
            v-model="newSupplyHolder.libelle"
            name="libelle"
            placeholder="Ex: Approvisionnement de l'article X"
          />
          <!-- {{ errorInput }} -->
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>

        <b-row>
          <b-col cols="12" xl="12">
            <b-form-group>
              <template #label>
                Date d'approvisionnement
                <span class="text-danger">*</span>
              </template>
              <flat-pickr
                v-model="newSupplyHolder.date_app"
                placeholder="Date d'approvisionnement"
                class="form-control bg-white"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- 
              -
              -
              -
              -
              -
              -
           -->

        <div
          v-for="(item_supply, index) in newSupply"
          :key="index"
          class="bg-light p-1 mt-1"
          style="border-radius: 10px"
        >
          <div class="mb-1 d-flex justify-content-between border-bottom p-50">
            <span>Detail de produit #{{ index + 1 }}</span>
            <div
              class="bg-white p-0 d-flex align-items-center justify-content-center cursor-pointer"
              style="border-radius: 100%; width: 25px; height: 25px"
            >
              <feather-icon icon="XIcon" @click="removeSupply(index)" />
            </div>
          </div>

          <b-row>
            <b-col>
              <b-form-group label="">
                <template #label>
                  fournisseur <span class="text-danger">*</span>
                </template>

                <v-select
                  v-model="newSupply.fournisseur_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Choisir un fournisseur"
                  class="bg-white"
                  :options="vendors"
                  label="libelle"
                >
                  <!-- <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template> -->
                </v-select>
                <span
                  class="text-danger"
                  style="font-size: 12px"
                  v-if="errorInput.path === 'fournisseur_id'"
                >
                  {{ errorInput.message }}
                </span>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Libellé">
                <template #label>
                  Pour quel article ? <span class="text-danger">*</span>
                </template>

                <v-select
                  v-model="newSupply[index].article_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="libelle"
                  :options="articles"
                  placeholder="Choisir un article"
                  class="bg-white"
                />
                <!-- <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template> -->
                <span
                  class="text-danger"
                  style="font-size: 12px"
                  v-if="errorInput.path === 'article_id' && qIndex === index"
                >
                  {{ errorInput.message }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Prix de vente">
                <template #label>
                  Prix de vente <span class="text-danger">*</span>
                </template>

                <b-form-input
                  id="libelle"
                  v-model="newSupply[index].prix_unitaire"
                  name="libelle"
                  placeholder="Prix de vente"
                  type="number"
                  min="0"
                />
                <span
                  class="text-danger"
                  style="font-size: 12px"
                  v-if="errorInput.path === 'prix_unitaire'"
                >
                  {{ errorInput.message }}
                </span>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Quantité">
                <template #label> Quantité <span class="text-danger">*</span> </template>

                <b-form-input
                  id="libelle"
                  v-model="newSupply[index].qte"
                  name="libelle"
                  placeholder="Choisir une quantité"
                  type="number"
                  min="0"
                />
                <span
                  class="text-danger"
                  style="font-size: 12px"
                  v-if="errorInput.path === 'qte'"
                >
                  {{ errorInput.message }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Prix de vente -->

          <!-- Prix de vente -->

          <!-- Prix de vente -->
        </div>

        <!-- 
              -
              -
              -
              -
              -
              -
           -->

        <b-button @click.prevent="addInfoArticle" class="mt-2">
          <feather-icon icon="PlusIcon" class="mx-auto" />
          Ajouter un article
        </b-button>
      </b-card>
    </b-col>

    <b-col cols="12" md="3" xl="4">
      <b-card class="">
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.prevent="addSupplyStore"
          class="w-100 d-flex justify-content-center"
        >
          <span v-if="state.loading === false" class="text-center">Enregistrer </span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardTitle,
  BCardText,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { reactive, toRefs, ref, onMounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import URL from "../../request";
import { toast_orign, toast_sucess } from "@/utils/qToast";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardTitle,
    BCardText,
    BCard,
    vSelect,
    flatPickr,
  },
  setup(props, { root }) {
    const state = reactive({
      count: 0,
      loading: false,
    });

    const errorInput = reactive({
      path: "",
      message: "",
    });

    const qIndex = ref(null);

    const newSupplyHolder = reactive({
      libelle: "",
      date_app: moment(new Date()).format("YYYY-MM-DD"),
    });
    const newSupply = ref([]);

    const addInfoArticle = () => {
      newSupply.value.push({
        id: newSupply.value.length + 1,
        article_id: "",
        prix_unitaire: "",
        fournisseur_id: "",
        qte: "",
      });
    };

    onMounted(async () => {
      await getArticles();
      await getVendor();
    });

    const validateSupply = async () => {
      let isValid = true;
      if (newSupplyHolder.libelle === "") {
        errorInput.path = "libelle";
        errorInput.message = "Le libellé est obligatoire";
        isValid = false;
      } else if (newSupplyHolder.date_app === "") {
        errorInput.path = "date_app";
        errorInput.message = "La date est obligatoire";
        isValid = false;
      }
      return isValid;
    };

    const validateSupplyArticle = async () => {
      const digit = /^\d+$/;
      let isValidArticle = true;
      const articles = newSupply.value;

      for (let i = 0; i < articles.length; i++) {
        const element = articles[i];

        if (element.article_id === "") {
          errorInput.path = "article_id";
          errorInput.message = "L'article est obligatoire";
          isValidArticle = false;
          qIndex.value = newSupply.value.indexOf(element);
        } else if (element.prix_unitaire === "") {
          errorInput.path = "prix_unitaire";
          errorInput.message = "Le prix de vente est obligatoire";
          isValidArticle = false;
          qIndex.value = newSupply.value.indexOf(element);
        } else if (element.qte === "") {
          errorInput.path = "qte";
          errorInput.message = "La quantité est obligatoire";
          isValidArticle = false;
          qIndex.value = newSupply.value.indexOf(element);
        } else if (!digit.test(element.prix_unitaire)) {
          errorInput.path = "prix_unitaire";
          errorInput.message = "Le prix de vente doit être un nombre";
          isValidArticle = false;
          qIndex.value = newSupply.value.indexOf(element);
        } else if (!digit.test(element.qte)) {
          errorInput.path = "qte";
          errorInput.message = "La quantité doit est un nombre";
          isValidArticle = false;
          qIndex.value = newSupply.value.indexOf(element);
        }
      }

      return isValidArticle;
    };

    const addSupplyStore = async () => {
      const [isValid, isValidArticle] = await Promise.all([
        validateSupplyArticle(),
        validateSupply(),
      ]);

      if (newSupply.value.length === 0) {
        toast_orign(
          root,
          "Erreur vaditation",
          "AlertCircleIcon",
          "Veuillez ajouter un article",
          "danger",
          "top-right"
        );
        return;
      }

      console.log(isValid === true, isValidArticle === true);

      if (isValid && isValidArticle) {
        state.loading = true;
        try {
          const newSupplyStore = {
            libelle: "",
            article_id: [],
            prix_unitaire: [],
            fournisseur_id: [],
            qte: [],
            date_app: "",
          };
          newSupplyStore.libelle = newSupplyHolder.libelle;
          newSupplyStore.date_app = newSupplyHolder.date_app;

          for (let i = 0; i < newSupply.value.length; i++) {
            const el = newSupply.value[i];

            newSupplyStore.article_id.push(el.article_id.id);
            newSupplyStore.prix_unitaire.push(el.prix_unitaire);
            newSupplyStore.fournisseur_id.push(el.fournisseur_id.id);
            newSupplyStore.qte.push(el.qte);
          }

          const { data } = await axios.post(URL.SUPPLY_STORE, newSupplyStore, {
            headers: {
              Accept: "application/json",
            },
          });
          if (data) {
            toast_sucess(
              root,
              "success",
              "top-right",
              "Enregistrement effectué avec succès"
            );
            state.loading = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const removeSupply = (index) => {
      newSupply.value.splice(index, 1);
    };

    const vendors = ref([]);
    const articles = ref([]);

    const getVendor = async () => {
      const url = URL.FOURNISSEUR_LIST;

      try {
        const { data } = await axios.get(url);

        if (data) {
          console.log(data);
          vendors.value = data[0];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getArticles = async () => {
      const url = URL.ARTICLE_LIST;
      try {
        const { data } = await axios.get(url);

        if (data) {
          console.log(data);
          articles.value = data[0];
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      errorInput,
      state,
      newSupply,
      newSupplyHolder,
      vendors,
      articles,
      addInfoArticle,
      addSupplyStore,
      removeSupply,
      qIndex,

      getVendor,
      getArticles,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
